<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Setting Aplikasi</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Setting Aplikasi</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-8 lg:col-offset-2">
        <div class="card bg-white">
          <DataTable
            :value="items"
            :loading="isLoading"
            :row-hover="true"
            class="p-datatable-sm"
            @request="onRequestData"
          >
            <Column field="key" headerClass="hidden">
              <template #body="{ data }">
                <div class="font-bold">{{ data.key.toUpperCase() }}</div>
                <br />
                <div v-if="data.desc" class="font-italic text-500">
                  {{ data.desc }}
                </div>
              </template>
            </Column>
            <Column field="value" headerClass="hidden" style="width: 40%">
              <template #body="{ data }">
                <div v-if="data.type === 'boolean'">
                  <div v-if="data.value === '1'">Ya</div>
                  <div v-else>Tidak</div>
                </div>
                <div v-else>{{ data.value }}</div>
              </template>
            </Column>
            <Column headerClass="hidden">
              <template #body="{ data }">
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  class="p-button-outlined p-button-secondary p-button-text mr-2"
                  @click="onEditData(data)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog
      :header="'Edit Setting ' + key"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '25vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-setting
        :item="form"
        :loading="isLoadingSave"
        :setting="setting"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>

    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SettingService from '@/services/SettingService'
import FormSetting from '@/components/master/FormSetting'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Setting',
  components: {
    FormSetting,
    Hotkey,
  },
  data() {
    return {
      settingService: null,
      dialog: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      form: {},
      key: '',
      setting: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: '1',
        filters: null,
      },
      filters: {
        div: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  created() {
    this.settingService = new SettingService()
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.settingService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data setting', this)
        })
        .finally(() => (this.isLoading = false))

    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.key = data.key.toUpperCase()
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      const item = {}
      item.value = data.value
      this.settingService
        .update(data.id, item)
        .then((res) => {
          if (res.data.status === 1) {
            this.loadData()
            this.$toast.add({
              severity: 'success',
              summary: 'Data setting',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.dialog = false
            location.reload();
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data setting', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>

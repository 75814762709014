<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid" v-if="form.key == 'cabang'">
            <div class="field col-12 md:col-12">
              <Dropdown
                v-model="form.value"
                :filter="true"
                :options="cabang"
                class="w-full"
                input-class="w-full"
                optionValue="no_cabang"
                optionLabel="nama_cabang"
                placeholder="Pilih Cabang"
                :disabled="id"
                :class="{
                  'p-invalid': v$.form.value.$invalid && submitted,
                }"
              />
              <small
                v-if="
                  (v$.form.value.$invalid && submitted) ||
                  v$.form.value.$pending.$response
                "
                class="p-error"
                >{{ v$.form.value.required.$message }}</small
              >
            </div>
          </div>
          <div class="formgrid grid" v-if="form.type === 'boolean'">
            <div class="field col-12 md:col-12">
              <Dropdown 
                v-model="form.value"
                :options="selectionValue"
                optionLabel="name"
                optionValue="value"
                placeholder="Pilih Ya atau Tidak"
                class="w-full"
                :class="{
                  'p-invalid': v$.form.value.$invalid && submitted,
                }"
              />
              <small
                v-if="
                  (v$.form.value.$invalid && submitted) ||
                  v$.form.value.$pending.$response
                "
                class="p-error"
                >{{ v$.form.value.required.$message }}</small
              >
            </div>
          </div>
          <div class="formgrid grid" v-else>
            <div class="field col-12 md:col-12">
              <InputText
                v-model="form.value"
                class="w-full"
                input-class="w-full"
                maxlength="10"
                :class="{
                  'p-invalid': v$.form.value.$invalid && submitted,
                }"
              />
              <small
                v-if="
                  (v$.form.value.$invalid && submitted) ||
                  v$.form.value.$pending.$response
                "
                class="p-error"
                >{{ v$.form.value.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, numeric } from '@vuelidate/validators'
import { ref } from 'vue';

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    cabang: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      selectionValue: ref([
        {name: 'Ya', value: '1'},
        {name: 'Tidak', value: '0'}
      ])
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (
        this.editMode == true &&
        (this.form.div == '' || this.form.div == null)
      ) {
        return
      }

      if (!isFormValid) {
        return
      }
      
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        value: {
          required: helpers.withMessage(
            'Nilai harus diisi angka numerik.',
            required
          ),
          numeric,
        },
      },
    }
  },
}
</script>
